<template>
  <div
    id="property-main-details"
    class="row pt-1"
  >
    <div
      v-if="isAddPropertyForm"
      class="col-12"
    >
      <div class="row no-gutters">
        <div class="font-18 font-weight-bold">
          Property
        </div>
      </div>
      <div class="row no-gutters">
        <div class="font-15">
          Any updates made to this information will affect all other units associated with this property.
        </div>
      </div>
    </div>

    <div
      v-if="isAddPropertyForm"
      class="col-12"
    >
      <PropertyTypeField
        v-if="!(propertyWithSameAddressRef && propertyWithSameAddressRef.id)"
        :is-add-property-form="isAddPropertyForm"
        :unit.sync="currentUnit"
        :property.sync="property"
        @update="emitPropertyPayloadChanged('property_type')"
      />
    </div>
    <div class="col-12">
      <p
        v-if="isIntegrationEnabledIWithoutSyndication && !isAddPropertyForm"
        class="text-gray-dark mb-3 mt-1"
      >
        If you need to make any updates, please do so in {{ integrationType }}, and the updates will automatically synchronize into Showdigs.
      </p>
      <ElFormItem
        :prop="!isAddPropertyForm ? 'property.address' : 'address'"
        :rules="propertyPayloadRulesRef.address"
      >
        <SdAddressField
          :address="property.address"
          :disabled="isIntegrationEnabled && !isSyndicated && !property.is_null_location && !isAddPropertyForm"
          @set-address-field="setAddressField"
        />
      </ElFormItem>
      <PropertyUnsupportedAreaAlert
        v-if="!isPropertyInMarket"
        class="mt-3"
      />
      <PropertyInvalidAddressAlert
        v-if="isInvalidPropertyAddress"
        :suggested-address="suggestedPropertyAddress"
        class="mt-3"
      />
      <PropertyDuplicatedAddressAlert
        v-if="propertyWithSameAddressRef && propertyWithSameAddressRef.id"
        :duplicated-property="propertyWithSameAddressRef"
        class="mt-3"
      />
    </div>

    <div
      v-if="isIframeEmbedEnabled === false && !hasAppfolioNewIntegration && isAddPropertyForm"
      class="col-12"
    >
      <SdFloatLabel
        :with-optional="!isPropertyCodeRequiredRef"
        for-input="pms_code"
      >
        <ElFormItem
          :prop="!isAddPropertyForm ? 'property.pms_code' : 'pms_code'"
          :rules="isPropertyCodeRequiredRef ? propertyPayloadRulesRef.pms_code_required : propertyPayloadRulesRef.pms_code"
        >
          <ElTooltip
            :visible-arrow="false"
            :disabled="$viewport.lt.md"
            popper-class="popover-panel"
            placement="right-start"
          >
            <template slot="content">
              <i class="sdicon-info-circle" />
              <p>The property name or code you use in your AppFolio account</p>
            </template>
            <ElInput
              id="pms_code"
              v-model="property.pms_code"
              placeholder="AppFolio property code"
              @input="emitPropertyPayloadChanged('pms_code')"
            />
          </ElTooltip>
        </ElFormItem>
      </SdFloatLabel>
    </div>
    <div
      v-if="isAddPropertyForm && property.picture_url && !isIntegrationEnabledIWithoutSyndication"
      class="col-12"
    >
      <SdImageField
        :src="property.picture_url"
        placeholder-text="Exterior property image"
        remove-button-location="top-right"
        image-output-quality="0.85"
        image-output-type="image/jpeg"
        accept-types="image/png,image/jpeg,image/jpg"
        :disabled="isIntegrationEnabled && !isSyndicated"
        file-type-mismatch-message="Picture must be JPG, JPEG or PNG format"
        @set-image-field="setImageField"
        @unset-image-field="unsetImageField"
      />
    </div>

    <div class="col-12 pt-3">
      <div class="row no-gutters">
        <ElButton
          v-if="isAddPropertyForm && property.units.length > 0 && property.units[0].name && !isIntegrationEnabled"
          size="small"
          type="primary"
          @click="addUnit"
        >
          Add Another Unit
        </ElButton>
      </div>
    </div>

    <!-- Unit level -->
    <template v-for="(unit, index) in property.units">
      <div
        v-if="isUnitVisible(unit.id)"
        :key="`unit-input-${index}`"
        class="col-12"
      >
        <div class="row no-gutters align-items-center">
          <ElFormItem
            class="col"
            :prop="!isAddPropertyForm ? 'property.units.' + index + '.name' : 'units.' + index + '.name'"
            :rules="{ validator: unitNameValidator }"
          >
            <SdFloatLabel label="Unit name">
              <ElInput
                :id="`unit-name-${index}`"
                v-model="unit.name"
                :placeholder="`Unit name ${property.units.length === 1 ? '(optional)' : ''}`"
                :disabled="unitNameDisabled"
                @input="emitPropertyPayloadChanged('units')"
              />
            </SdFloatLabel>
          </ElFormItem>
          <UnitDuplicateNameAlert
            v-if="resolveUnitWithSameName(unit)"
            class="mt-3"
          />
          <div
            v-if="index > 0 && canUnitBeDeleted(unit)"
            class="ml-2 pointer"
            @click="deleteUnit(index)"
          >
            <i class="sdicon-trash font-24 text-red" />
          </div>
        </div>
      </div>

      <div
        v-if="isIframeEmbedEnabled && isUnitVisible(unit.id)"
        :key="`unit-internal-id-input-${index}`"
        class="col-12"
      >
        <div class="row no-gutters">
          <div
            :key="`code-input-${index}`"
            class="col-12 col-md-4"
          >
            <ElFormItem
              :prop="!isAddPropertyForm ? 'property.units.' + index + '.pms_code' : 'units.' + index + '.pms_code'"
              :rules="propertyPayloadRulesRef.unit_pms_code"
            >
              <SdFloatLabel label="Internal ID">
                <ElInput
                  v-model="unit.pms_code"
                  placeholder="Internal ID"
                  :disabled="propertyWithSameAddressRef && propertyWithSameAddressRef.id"
                  @input="emitPropertyPayloadChanged('units')"
                />
              </SdFloatLabel>
            </ElFormItem>
          </div>
        </div>
      </div>

      <div
        v-if="isUnitVisible(unit.id)"
        :key="`unit-floorplan-input-${index}`"
        class="col-12"
      >
        <UnitListings
          v-if="!(propertyWithSameAddressRef && propertyWithSameAddressRef.id)"
          class="row"
          :unit.sync="unit"
          :property-unit-index="index"
          :listing-data-prefill="isIntegrationEnabled ? {} : null"
          :mode="isAddPropertyForm ? 'ADD' : 'EDIT'"
          :business="business"
          :with-availability-date="withAvailabilityDate"
          @update-unit="emitPropertyPayloadChanged('units')"
          @update-rent="payload => preRentUpdate(payload)"
          @update-availability-date="date => $emit('update-availability-date', date)"
          @update-property-url="url => $emit('update-property-url', url)"
          @update-move-in-cost="moveInCost => $emit('update-move-in-cost', moveInCost)"
        />
      </div>

      <div
        v-if="isUnitVisible(unit.id) && !isIntegrationEnabledIWithoutSyndication && !isAddPropertyForm"
        :key="`property-type-field-${index}`"
        class="col-12"
      >
        <PropertyTypeField
          v-if="!(propertyWithSameAddressRef && propertyWithSameAddressRef.id)"
          :is-add-property-form="isAddPropertyForm"
          :unit.sync="currentUnit"
          :property.sync="property"
          @update="emitPropertyPayloadChanged('property_type')"
        />
      </div>

      <div
        v-if="isUnitVisible(unit.id) && !isAddPropertyForm && !isIntegrationEnabledIWithoutSyndication"
        :key="`unit-tags-input-${index}`"
        class="col-12"
      >
        <UnitTags
          v-if="!(propertyWithSameAddressRef && propertyWithSameAddressRef.id)"
          class="row"
          :selected-tags="unit.tags"
          :selectable-tags="selectableTags"
          :unit.sync="unit"
          :mode="isAddPropertyForm ? 'ADD' : 'EDIT'"
          @update-unit="emitPropertyPayloadChanged('units')"
        />
      </div>
      <UnitGenerateAd
        v-if="isUnitVisible(unit.id) && !isAddPropertyForm && AllowedGenerate && !isIntegrationEnabledIWithoutSyndication"
        :key="`unit-generate-ad-input-${index}${UnitGenerateAdComponentKey}`"
        prop-class="col-12 text-right"
        :is-disabled="!measurementsSet || !rentSet"
        :is-edit-form="!isAddPropertyForm"
        :property="property"
        :unit.sync="unit"
        :rent="rentSet"
        :additional-information="additionalInformation"
        @update-payload="value => updateTitleAndDescription(value)"
        @update-additional-information="value => updateAdditionalInformation(value)"
      />
      <div
        v-if="isUnitVisible(unit.id) && !isAddPropertyForm && !isIntegrationEnabledIWithoutSyndication"
        :key="`unit-title-input-${index}`"
        class="col-12"
      >
        <UnitTitleAndDescription
          class="row"
          :unit.sync="unit"
          :property-unit-index="index"
          :mode="isAddPropertyForm ? 'ADD' : 'EDIT'"
          @update-unit="emitPropertyPayloadChanged('units')"
        />
      </div>

      <div
        v-if="isUnitVisible(unit.id) && !isIntegrationEnabledIWithoutSyndication"
        :key="`unit-media-gallery-wrapper-${index}`"
        class="col-12"
      >
        <UnitMediaGallery
          :ref="`unit-media-gallery-${index}`"
          :key="`unit-media-gallery-${index}`"
          class="row"
          :unit.sync="unit"
          :property-unit-index="index"
          :mode="isAddPropertyForm ? 'ADD' : 'EDIT'"
          :show-hero-image="false"
          @update-unit="emitPropertyPayloadChanged('units')"
        />
      </div>

      <div
        v-if="isAddPropertyForm && isUnitVisible(unit.id)"
        :key="`unit-divider-${index}`"
        class="col-12 justify-content-end"
      >
        <ElDivider class="my-0" />
        <div
          v-if="isAddPropertyForm && property.units.length > 0 && !isIntegrationEnabled"
          class="row mt-3 justify-content-center align-items-center"
        >
          <!-- <div class="col pt-0 font-15">
          <i class="sdicon-info-circle font-17 text-primary" />
          You can add more units to this property by pressing
        </div> -->
          <div
            class="mx-2 font-17 pointer add-unit-cta"
            size="medium"
            @click="addUnit"
          >
            + Add Another Unit
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  computed, onMounted, ref, reactive,
} from '@vue/composition-api';

import get from 'lodash.get';
import UnitListings from '@/components/unit/UnitListings';
import Showing from '@/constants/Showing';
import { PmsCodeRule, PmsCodeRequiredRule } from '@/validator/PmsCodeRules';
import { getBusinessSourceType } from '@/utils/BusinessSourceUtil';
import { capitalizeFirstLatter } from '@/utils/StringUtil';
import UnitDuplicateNameAlert from '@/components/unit/UnitDuplicateNameAlert.vue';
import SdAddressField from '../common/form/SdAddressField';

import PropertyTypeField from './PropertyTypeField';
import PropertyUnsupportedAreaAlert from './PropertyUnsupportedAreaAlert';
import PropertyInvalidAddressAlert from './PropertyInvalidAddressAlert';
import PropertyDuplicatedAddressAlert from './PropertyDuplicatedAddressAlert';
import SdImageField from '../common/form/SdImageField';
import Regex from '../../constants/Regex';
import UnitTags from '../unit/UnitTags';
import UnitTitleAndDescription from '../unit/UnitTitleAndDescription';
import UnitMediaGallery from '../unit/UnitMediaGallery';
import UnitGenerateAd from '../unit/UnitGenerateAd';

const regexConstants = Regex;

export default {
  name: 'PropertyMainDetails',
  components: {
    UnitDuplicateNameAlert,
    PropertyTypeField,
    UnitTitleAndDescription,
    UnitListings,
    SdAddressField,
    SdImageField,
    PropertyUnsupportedAreaAlert,
    PropertyInvalidAddressAlert,
    PropertyDuplicatedAddressAlert,
    UnitTags,
    UnitMediaGallery,
    UnitGenerateAd,
  },
  props: {
    selectableTags: {
      type: Array,
      required: true,
      default: () => [],
    },
    property: {
      type: Object,
      required: false,
      default: null,
    },
    isAddPropertyForm: {
      type: Boolean,
      default: false,
    },
    isPropertyInMarket: {
      type: Boolean,
      required: true,
    },
    businessSource: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isInvalidPropertyAddress: {
      type: Boolean,
      required: true,
    },
    hasAppfolioNewIntegration: {
      type: Boolean,
      default: false,
    },
    suggestedPropertyAddress: {
      type: String,
      required: false,
      default: null,
    },
    propertyWithSameAddressRef: {
      type: Object,
      required: false,
      default: null,
    },
    showingProfileType: {
      type: String,
      required: false,
      default: null,
    },
    unitWithSameName: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const isIntegrationEnabled = store.getters['BusinessSource/isIntegrationEnabled'];
    const isSyndicated = context.root.$store.getters['Auth/businessSyndicated'];
    const isLegacyIntegrationEnabled = store.getters['BusinessSource/isLegacyIntegrationEnabled'];
    const isIntegrationEnabledIWithoutSyndication = ref(resolveIsIntegrationEnabledIWithoutSyndication());
    const allUnitsAreNotShowing = ref(false);
    const propertyPayloadRulesRef = ref(propertyDetailsRules);
    const isPropertyCodeRequiredRef = ref(false);
    const currentUnit = computed(() => (props.isAddPropertyForm ? props.property.units[0] : props.property.units.find((u) => u.isRootUnit)));
    const business = context.root.$store.getters['Auth/business'];
    const isIframeEmbedEnabled = Boolean(business?.embed_frame_sources);
    const isShowingProfileNotPublishListing = props.showingProfileType !== Showing.TYPE_PUBLISH_LISTING;
    const withAvailabilityDate = ref(isShowingProfileNotPublishListing);
    const AllowedGenerate = ref(!resolveDisableGenerate());
    const measurementsSet = ref(resolveMeasurementsSet(0));
    const rentSet = ref(resolveRentSet(0));
    const additionalInformation = ref('');
    const UnitGenerateAdComponentKey = ref(0);
    const unitNameDisabled = Boolean((!!props.propertyWithSameAddressRef && !!props.propertyWithSameAddressRef.id)
      || (isIntegrationEnabled && !isSyndicated && !isLegacyIntegrationEnabled));
    if (props.businessSource && props.businessSource.business_source_identifier) {
      isPropertyCodeRequiredRef.value = Boolean(currentUnit.value?.listing_identifier);
    }
    const integrationType = capitalizeFirstLatter(getBusinessSourceType());
    onMounted(async () => {
      allUnitsAreNotShowing.value = props.property.units.every((unit) => !unit.active_showing_profile);
      emitPropertyPayloadChanged();
      if (!props.isAddPropertyForm) {
        context.emit('update-property-payload', { units: props.property.units });
      } else {
        context.emit('update-property-payload', {
          units: [
            {
              name: '',
              bedrooms: '',
              bathrooms: null,
              size: null,
              pms_code: null,
              images: [],
              tags: [],
              video: '',
              short_description: '',
              description: '',
            },
          ],
        });
      }
    });

    function isUnitVisible(unitId) {
      return unitId === currentUnit.value.id || props.isAddPropertyForm;
    }

    return {
      isIntegrationEnabled,
      isLegacyIntegrationEnabled,
      currentUnit,
      propertyPayloadRulesRef,
      allUnitsAreNotShowing,
      isPropertyCodeRequiredRef,
      isIframeEmbedEnabled,
      isUnitVisible,
      canUnitBeDeleted,
      emitPropertyPayloadChanged,
      setAddressField,
      setImageField,
      unsetImageField,
      unitNameValidator,
      addUnit,
      deleteUnit,
      uploadUnitMediaGalleryImages,
      get,
      business,
      measurementsSet,
      rentSet,
      updateTitleAndDescription,
      withAvailabilityDate,
      preRentUpdate,
      AllowedGenerate,
      additionalInformation,
      UnitGenerateAdComponentKey,
      updateAdditionalInformation,
      isSyndicated,
      unitNameDisabled,
      isIntegrationEnabledIWithoutSyndication,
      integrationType,
      resolveUnitWithSameName,
    };

    function resolveIsIntegrationEnabledIWithoutSyndication() {
      return isIntegrationEnabled && !isSyndicated;
    }

    function resolveUnitWithSameName(relevantUnit) {
      const unitWithTheSameName = props.property.units.find((unit) => relevantUnit.name === unit.name && relevantUnit.id !== unit.id);
      return !!unitWithTheSameName;
    }

    function updateAdditionalInformation(value) {
      additionalInformation.value = value;
    }

    function resolveDisableGenerate() {
      if (!props?.property?.units[0]?.active_showing_profile) {
        return true;
      }
      const isIntegrationEnabled = store.getters['BusinessSource/isIntegrationEnabled'];
      return isIntegrationEnabled && !isSyndicated
        && (Boolean(props.property.units[0].listing_identifier) || Boolean(props.property.units[0].origin_id));
    }

    function preRentUpdate(payload) {
      context.emit('update-rent', payload);
      rentSet.value = payload.rent;
      UnitGenerateAdComponentKey.value += 1;
    }

    function updateTitleAndDescription(value) {
      props.property.units[0].description = value.description;
      props.property.units[0].short_description = value.short_description;
      emitPropertyPayloadChanged('units');
    }

    function emitPropertyPayloadChanged(key) {
      const update = key ? { [key]: props.property[key] } : props.property;
      context.emit('update-property-payload', update);
      UnitGenerateAdComponentKey.value += 1;
      measurementsSet.value = resolveMeasurementsSet(0);
    }

    function resolveRentSet(index) {
      if (props.isAddPropertyForm) {
        return false;
      }
      const unit = props.property.units[index];
      return unit.last_showing_profile?.rent;
    }

    function resolveMeasurementsSet(index) {
      if (props.isAddPropertyForm) {
        return false;
      }
      const unit = props.property.units[index];
      return unit.bedrooms && unit.bathrooms && unit.size;
    }

    function canUnitBeDeleted(unit) {
      return !isIntegrationEnabled
        && props.property.units.length > 1
        && !unit.active_showing_profile
        && (props.isAddPropertyForm || unit.id !== Number(context.root.$route.params.unit_id));
    }

    function deleteUnit(index) {
      const nextUnitsArray = props.property.units.filter((item, i) => i !== index);
      context.emit('update-property-payload', { units: nextUnitsArray });
    }

    function addUnit() {
      props.property.units.push({
        name: '',
        bedrooms: '',
        bathrooms: null,
        size: null,
        pms_code: null,
        images: [],
        tags: [],
        video: '',
        short_description: '',
        description: '',
      });

      try {
        context.root.$nextTick(() => {
          const addedUnitNameRef = document.querySelector(`#unit-name-${props.property.units.length - 1}`);
          const elementRect = addedUnitNameRef.getBoundingClientRect();
          window.scrollTo({
            top: elementRect.top + window.scrollY - 20,
            behavior: 'smooth',
          });
          setTimeout(() => {
            addedUnitNameRef.focus();
          }, 500);
        });
      } catch { }
    }

    // eslint-disable-next-line consistent-return
    function unitNameValidator(rule, value, callback) {
      if (props.property.units.length === 1 || isIntegrationEnabled) {
        return callback();
      }
      if (!value) {
        return callback('Unit names are required when there\'s more than one unit');
      }

      callback();
    }

    function setAddressField(address) {
      context.emit('update-property-payload', { address });
    }

    function setImageField(imgObj) {
      context.emit('update-property-payload', { picture: imgObj });
    }

    async function uploadUnitMediaGalleryImages() {
      const unitsMediaGalleryPromises = props.property.units.map((_, index) => context.refs?.[`unit-media-gallery-${index}`]?.[0]?.uploadImages?.());
      await Promise.all(unitsMediaGalleryPromises);
      context.emit('update-property-payload', { units: props.property?.units });
    }

    function unsetImageField() {
      setImageField(null);
    }
  },
};

export const propertyDetailsRules = {
  pms_code: [PmsCodeRule],
  pms_code_required: [PmsCodeRequiredRule],
  unit_pms_code: [{
      validator: (rule, value, callback) => {
        if (!value || !value.trim()) {
          return callback(new Error('Unit ID is required'));
        }

        value = value.trim();

        if (value.match(regexConstants.url)) {
          return callback(
          new Error('Unit ID cannot be a URL'),
        );
        }

        if (value.length > 100) {
          return callback(
          new Error('Unit ID is too long'),
        );
        }

        return callback();
      },
    }],
  address: [
    {
      required: true,
      type: 'object',
      message: 'Address name is required',
      trigger: 'change',
    },
  ],
  listing_manager_id: [
    {
      required: true,
      message: 'Listing owner is required',
    },
  ],
};
</script>

<style lang="scss" scoped>
#property-main-details {

  .add-unit-cta {
    transition: color 0.125s ease-in;
    color: theme-color('primary');
    &:hover {
      color: theme-color('primary-dark');
    }
  }
}
</style>
